export function getLatestDownloadLink(platform: string): string {
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']

    let platformExtension = 'exe'

    if (windowsPlatforms.includes(platform)) {
        platformExtension = 'exe'
    } else if (macosPlatforms.includes(platform)) {
        platformExtension = 'dmg'
    } else if (platform.startsWith('Linux')) {
        platformExtension = 'AppImage'
    }
    return `https://firebasestorage.googleapis.com/v0/b/storywise-6dde9.appspot.com/o/releases%2FStoryWise-Setup-Latest.${platformExtension}?alt=media&token=055b486a-2454-4963-a3c3-f84889a3a568`

}