import {FeatureHighlight} from "./FeatureHighlight";
import CreateIcon from "@mui/icons-material/Create";
import {Grid} from "@mui/material";
import React from "react";
import PublishIcon from '@mui/icons-material/Publish';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

export function FeatureHighlights() {
    return (
        <Grid container padding={'64px'}>
            <FeatureHighlight icon={<CreateIcon/>} header={'Write with ease'}
                              description={'A powerful editor, capable of highlighting your writing rhythm and tracking your story elements.'}/>
            <FeatureHighlight icon={<LocalOfferIcon/>} header={'Create story elements'}
                              description={'A new character, location or an important item? All can be turned into story elements. ' +
                                  'Add an image, a description, tags and synonyms, which can be retrieved with a single click.'}/>
            <FeatureHighlight icon={<PublishIcon/>} header={'Export'}
                              description={'Publish your story, either to HTML or markdown, or synchronize with the StoryWise cloud.'}/>
        </Grid>
    )
}