import React from 'react';
import {TopBar} from "./TopBar/TopBar";
import {LandingPage} from "./LandingPage/LandingPage";
import {Footer} from "./Footer";

function App() {
    return (
        <div>
            <header>
                <TopBar/>
            </header>
            <main>
                <LandingPage/>
            </main>
            <Footer/>
        </div>
    );
}

export default App;
