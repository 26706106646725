import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {createTheme, ThemeProvider} from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: '#052D39'
        }
    },
    components: {
        MuiTypography: {
            defaultProps: {
                // fontFamily:  `"Inter", sans-serif`,
            },
            styleOverrides: {
                body1: {
                    opacity: '0.6'
                },
                body2: {
                    opacity: '0.6'
                },
            }
        },
    },
});

ReactDOM.render(
    <>
        <ThemeProvider  theme={theme}>
            <App/>
        </ThemeProvider >
    </>,
    document.getElementById('root')
);
