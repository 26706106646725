import {AppBar, Avatar, Box, Button, Grid, IconButton, Toolbar, Tooltip, Typography} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import {getLatestDownloadLink} from "../utils/download";
import swLogo from '../logo64.png';
import {Mail} from "@mui/icons-material";

interface Props {


}

export const TOP_BAR_HEIGHT = 64

export function TopBar(props: Props) {
    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position="fixed" color={'inherit'} sx={{height: `${TOP_BAR_HEIGHT}px`}}>
                <Toolbar>
                    <Grid sx={{width: 48, height: 48}}>
                        <img src={swLogo} style={{width: 48, height: 48}}/>
                    </Grid>
                    <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                        StoryWise
                    </Typography>
                    <Tooltip title={"Contact us"}>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="Contact Us"
                            sx={{mr: 2}}
                            href={`mailto:support@storywise.app`}
                        >
                            <Mail/>
                        </IconButton>
                    </Tooltip>
                    <Button variant={'contained'} href={getLatestDownloadLink(window.navigator.platform)}>
                        Download now
                    </Button>
                </Toolbar>
            </AppBar>
        </Box>
    )
}