import {Box, Divider} from "@mui/material";
import React from "react";
import swLogo from "./logo64.png";

export function Footer() {
    return (
        <footer>
            <Divider/>
            <Box padding={4} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                <a href={'https://storywise.app'}>
                    <img alt={"StoryWise Logo"} src={swLogo} style={{width: 64, height: 64}}/>
                </a>
                <div>
                    <span>© 2022. All rights reserved</span>
                </div>
            </Box>
        </footer>
    )
}