import {Box} from "@mui/material";
import React from "react";
import {EyeCatcher} from "../EyeCatcher/EyeCatcher";
import {FeatureHighlights} from "../FeatureHighlight/FeatureHighlights";
import {TOP_BAR_HEIGHT} from "../TopBar/TopBar";

export function LandingPage() {
    return (
        <Box paddingTop={`${TOP_BAR_HEIGHT}px`}>
            <EyeCatcher/>
            <FeatureHighlights/>
        </Box>
    )
}