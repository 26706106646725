import {Avatar, Button, Grid, Typography, useTheme} from "@mui/material";
import React from "react";
import CreateIcon from "@mui/icons-material/Create";


interface Props {
    icon: JSX.Element;
    header: string;
    description: string;
}

export function FeatureHighlight(props: Props) {
    const {icon, header, description} = props;
    const theme = useTheme();
    return (
        <Grid item sm={4}>
            <Grid container alignContent={'center'} spacing={'10px'}>
                <Grid item sm={12} justifyContent={'center'} display={'flex'}>
                    <Avatar sx={{ width: 56, height: 56, backgroundColor: theme.palette.primary.dark }} color={theme.palette.primary.contrastText}>
                        {icon}
                    </Avatar>
                </Grid>
                <Grid item sm={12} justifyContent={'center'} display={'flex'}>
                    <Typography marginX={'auto'} variant={'h6'}>{header}</Typography>
                </Grid>
                <Grid item sm={12} justifyContent={'center'} display={'flex'}>
                    <Typography marginX={'auto'} variant={'body1'} textAlign={"center"}>{description}</Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}