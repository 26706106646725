import React from "react";

import {Button, Grid, Typography} from "@mui/material";
import cloudBackup from "../StoryWiseBackup.png";
import dualEditor from "../StoryWiseDualEditor.png";
import drawer from "../StoryWiseStoryElementDrawer.png";
import search from "../StoryWiseStorySearch.png";
import 'react-awesome-slider/dist/styles.css';
import {ImageSlider} from "../ImageSlider/ImageSlider";
import {getLatestDownloadLink} from "../utils/download";


export function EyeCatcher() {

    return (
        <Grid container bgcolor={'rgb(247, 249, 252)'} padding={'64px'}>
            <Grid sm item alignItems={'center'} justifyContent={'center'} display={'flex'}>
                <Grid item sm={6}>
                    <Grid item paddingBottom={2}>
                        <Typography variant={'h2'}>
                            Write better stories
                        </Typography>
                    </Grid>
                    <Grid item paddingBottom={4}>
                        <Typography variant={'body1'}>
                            Keep track of your story elements, analyze your writing rhythm, safely store cloud backups
                            of
                            your story and publish the way you want it.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button variant={'contained'} href={getLatestDownloadLink(window.navigator.platform)}>
                            Download now
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sm display={'flex'} justifyContent={'center'}>
                <ImageSlider images={[
                    {label: 'Story elements', imgPath: drawer, alt: 'Keep track of your story elements with StoryWise'},
                    {label: 'Thorough search', imgPath: search, alt: 'Find what you are looking for with StoryWise'},
                    {label: 'Cloud backup', imgPath: cloudBackup, alt: 'Create cloud backups and compare versions with StoryWise'},
                    {label: 'Dual editor', imgPath: dualEditor, alt: 'Compare and edit chapters side-by-side with StoryWise'},
                ]}/>
            </Grid>
        </Grid>
    )
}